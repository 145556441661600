<template>
  <div class="animated">
    <CustomModal
      :modalTitle="$t('delete')"
      :button1Title="$t('delete')"
      button1Class="btn btn-danger"
      :show="removeModal"
      @hide="removeModal = false"
      @onButton1Click="remove()"
    >
      {{ $t("deleteConfirm") }}
    </CustomModal>
    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("menu") }}
        <div class="card-header-actions">
          <BrandButton
            :text="$t('newMenu')"
            @click="add()"
            v-if="$auth.hasAuth($options, 'add')"
          ></BrandButton>
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table
          v-if="$auth.hasAuth($options, 'list')"
          :columns="columns"
          :data="list"
          :options="options"
          id="dataTable"
          class="dataGrid userGrid"
        >
          <template slot="parentMenu" slot-scope="props">
            {{ props.row.parentMenuTitle }}
          </template>
          <template slot="active" slot-scope="props">
            <b-badge
              size="lg"
              :variant="props.row.active ? 'success' : 'danger'"
              >{{ props.row.active ? $t("yes") : $t("no") }}</b-badge
            >
          </template>
          <template slot="buttons" slot-scope="props">
            <div class="nowrap">
              <b-button
                variant="primary icon"
                v-b-tooltip.hover
                :title="$t('edit')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'edit')"
              >
                <i class="fa fa-edit" @click="edit(props.row.id)"></i>
              </b-button>
              <b-button
                variant="danger icon"
                v-b-tooltip.hover
                :title="$t('delete')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'remove')"
              >
                <i
                  class="fa fa-trash"
                  @click="showRemoveModal(props.row.id)"
                ></i>
              </b-button>
              <b-button
                v-if="!props.row.active && $auth.hasAuth($options, 'active')"
                variant="success icon"
                v-b-tooltip.hover
                :title="$t('active')"
                class="mr-1 btn-brand"
              >
                <i class="fa fa-check-square" @click="active(props.row.id)"></i>
              </b-button>
              <b-button
                v-if="props.row.active && $auth.hasAuth($options, 'active')"
                variant="secondary icon"
                v-b-tooltip.hover
                :title="$t('deactive')"
                class="mr-1 btn-brand"
              >
                <i
                  class="fa fa-minus-square"
                  @click="deactive(props.row.id)"
                ></i>
              </b-button>
            </div>
          </template>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import gridOptions from "../../shared/grid-options";
import http from "../../shared/http";
import { ClientTable } from "vue-tables-2";
import utility from "../../shared/utils/screen-helper";
import CustomModal from "../../widgets/form/CustomModal";
import BrandButton from "../../widgets/form/CustomBrandButton";
import toast from "../../shared/toast-helper";

export default {
  name: "MenuList",
  components: {
    ClientTable,
    CustomModal,
    BrandButton,
  },
  created() {
    this.getList();
  },
  methods: {
    active(menuId) {
      this.setActiveState(menuId, true);
    },
    deactive(menuId) {
      this.setActiveState(menuId, false);
    },
    setActiveState(menuId, state) {
      const model = {
        id: menuId,
        active: state,
      };
      http.post(`Menus/changeactivestatus`, model).then((response) => {
        toast.success(response.message);
        this.getList();
      });
    },
    getList() {
      let path = "/Menus/getlist";
      http.get(path).then((response) => {
        if (response) this.list = response;
        utility.scrollToTop();
      });
    },
    add() {
      this.$router.push({ path: "menu-add" });
    },
    edit(id) {
      this.$router.push({ path: `menu-add/${id}` });
    },
    showRemoveModal(id) {
      this.removeModal = true;
      this.selectedId = id;
    },
    remove() {
      http.post(`Menus/delete?id=${this.selectedId}`).then((response) => {
        if (response.success) {
          this.getList();
          this.removeModal = false;
          this.selectedId = 0;
          toast.success(response.message);
        }
      });
    },
  },
  data() {
    return {
      list: [],
      removeModal: false,
      selectedId: 0,
      columns: ["title", "url", "parentMenu", "active", "buttons"],
      options: {
        ...gridOptions.getAll(),
        debounce: 100,
        orderBy: {
          column: "id",
          ascending: false,
        },
        perPageValues: [10, 25, 50, 100],
        headings: {
          title: this.$t("title"),
          url: this.$t("url"),
          parentMenu: this.$t("parentMenu"),
          active: this.$t("active"),
          buttons: this.$t("operations"),
        },
      },
    };
  },
};
</script>